import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ModelLoading from "../common/ModelLoading";
import { getMobileOperatingSystem } from "../common/Utils";


function PlantShopQR(props) {
    const device = getMobileOperatingSystem();

    if (device === "iOS") {
        if (props.isPlantShop === true) {
            window.fbq("trackCustom", "PlantShop_QR_iOS");
        } else {
            window.fbq("trackCustom", "External_Download_iOS");
        }
        window.location = "https://apps.apple.com/us/app/floramis/id6444943805";

    } else if (device === "Android") {
        if (props.isPlantShop === true) {
            window.fbq("trackCustom", "PlantShop_QR_Android");
        } else {
            window.fbq("trackCustom", "External_Download_Android");
        }
        window.location = "market://details?id=com.Floramis.Floramis";

    } else {
        if (props.isPlantShop === true) {
            window.fbq("trackCustom", "PlantShop_QR_Unknown_Device");
        } else {
            window.fbq("trackCustom", "External_Download_Unknown_Device");
        }
        window.location = "https://floramis.com";
    }

    return (
        <div className='page'>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh"
            }}>
                <ModelLoading />
            </div>
        </div>
    );
}

export default PlantShopQR;