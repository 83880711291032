import { CircularProgress } from "@mui/material";

function ModelLoading(props) {

    return (
        <CircularProgress
            size={200}
            disableShrink={true}
            style={{
                color: "#268513",
            }}>
        </CircularProgress>
    );
}

export default ModelLoading;