/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState, useMemo } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame, useLoader, useThree, useGraph } from "@react-three/fiber";
import { LoopOnce, TextureLoader, Vector3, MeshToonMaterial } from "three";
import { AnimMixer } from "./SpriteMixer";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SkeletonUtils } from "three-stdlib";

const animMixer = AnimMixer();

export default function FloramiModel(props) {
    const { gl, viewport, size, mouse } = useThree();

    const group = useRef();
    const { scene, animations, materials } = useGLTF("/Snakey.glb");
    const [happyMap, neutralMap] = useLoader(TextureLoader, ["Happy_Sheet.png", "NeutralBlinking_Sheet.png"]);

    const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
    const { nodes } = useGraph(clone);

    const { actions } = useAnimations(animations, group);

    const happyMaterial = animMixer.Anim(happyMap, null, 5, 1);
    const neutralMaterial = animMixer.Anim(neutralMap, null, 5, 1);

    const happyAnim = animMixer.Action(happyMaterial, 0, 4, 1 / 10);
    const neutralAnim = animMixer.Action(neutralMaterial, 0, 4, 1 / 10);

    const [animMaterial, setAnimMaterial] = useState(neutralMaterial);

    const [startX, setStartX] = useState(0);
    const [finishedAnim, setFinishedAnim] = useState(props.isIdle);

    const [blinkingInterval, setBlinkingInterval] = useState(null);

    const toonMaterial = new MeshToonMaterial();
    toonMaterial.map = materials["Material #43.002"].map;

    useEffect(() => {
        if (finishedAnim) {
            setStartX(0);
        } else {
            setStartX(-viewport.width / 2);
        }
    }, [size, viewport, finishedAnim]);

    const IdleAnimFunc = () => {
        const tempInterval = setInterval(() => {
            neutralAnim.playOnce(false, true);
            setTimeout(() => {
                neutralAnim.playOnce(true, true);
            }, 200);
        }, 3000);
        setBlinkingInterval(tempInterval);

        actions.IdleAnimTrack.play();
        if (!finishedAnim) {
            setTimeout(JumpAnimFunc, 200);
        }
    };

    const WalkAnimFunc = () => {
        actions.WalkAnimTrack.crossFadeFrom(actions.IdleAnimTrack, 0.5, false);
        actions.WalkAnimTrack.play();
        const myInt = setInterval(() => {
            group.current.position.x += 0.03;
            group.current.rotation.y -= 0.002;
            if (group.current.position.x >= 0) {
                clearInterval(myInt);
                setFinishedAnim(true);
                JumpAnimFunc();
            }
        }, 10);
    };

    const JumpAnimFunc = () => {
        actions.HopAnimTrack.crossFadeFrom(actions.IdleAnimTrack, 0.5, false);
        actions.HopAnimTrack.setLoop(LoopOnce);
        actions.HopAnimTrack.play();
        setTimeout(() => {
            const myInt = setInterval(() => {
                try {
                    group.current.rotation.y -= 0.01;
                    if (Math.abs(group.current.rotation.y) <= 0.1) {
                        clearInterval(myInt);
                    }
                } catch {
                    clearInterval(myInt);
                }
            });
        }, actions.HopAnimTrack.getClip().duration * 300);

        setTimeout(() => {
            actions.WaveAnimTrack.crossFadeFrom(actions.HopAnimTrack, 0.5, false);
            clearInterval(blinkingInterval);
            setAnimMaterial(happyMaterial);
            WaveAnimLoop();
        }, actions.HopAnimTrack.getClip().duration * 1000);
    }

    const WaveAnimLoop = () => {
        actions.WaveAnimTrack.reset();
        actions.WaveAnimTrack.setLoop(LoopOnce);
        actions.WaveAnimTrack.clampWhenFinished = true;
        actions.WaveAnimTrack.play();
        happyAnim.playOnce(false, true);
        setTimeout(() => {
            happyAnim.playOnce(true, true);
            setTimeout(WaveAnimLoop, 1200);
        }, actions.WaveAnimTrack.getClip().duration * 900);
    }

    useEffect(() => {
        // Set material to use the meshtoon material

        IdleAnimFunc();
    }, [])

    useFrame((state, delta) => {
        animMixer.update(state.clock.getDelta());
    });

    if (!props.scale) {
        props.scale = 1;
    }
    if (!props.position) {
        props.position = [0, 0, 0];
    }
    if (!props.rotation) {
        props.rotation = [0, 0, 0];
    }

    return (
        <group ref={group} dispose={null}
            scale={10 * props.scale}
            position={[props.position[0], -1 + props.position[1], props.position[2]]}
            rotation={[props.rotation[0], props.rotation[1], props.rotation[2]]}>

            <group name="Scene">
                <group name="HopArmature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <primitive object={nodes.CATRig_Pelvis} />
                    <skinnedMesh
                        name="Face"
                        geometry={nodes.Face.geometry}
                        material={animMaterial}
                        skeleton={nodes.Face.skeleton}
                    />
                    <skinnedMesh
                        name="Body"
                        geometry={nodes.Body.geometry}
                        material={toonMaterial}
                        skeleton={nodes.Body.skeleton}
                    />
                </group>
                <group name="IdleArmature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <group
                        name="CATRig_Pelvis_1"
                        position={[0, -0.99, -10.09]}
                        rotation={[0.06, 1.57, 0]}
                    >
                        <group
                            name="CATRigL_Leg1_1"
                            position={[-0.08, 0, 5.41]}
                            rotation={[3.14, 0, -2.98]}
                        />
                        <group name="CATRigSpine_1">
                            <group name="CATRigHead_1" position={[10.68, 0, 0]} />
                        </group>
                        <group
                            name="CATRigR_Leg1_1"
                            position={[-0.08, 0, -5.41]}
                            rotation={[-3.14, 0, -2.98]}
                        />
                        <group
                            name="CATRigR_Arm_1"
                            position={[4.64, 0, -10.98]}
                            rotation={[-2.91, 0.94, -2.91]}
                        />
                        <group
                            name="CATRigL_Arm_1"
                            position={[4.64, 0, 10.98]}
                            rotation={[2.94, -0.91, -2.92]}
                        />
                    </group>
                </group>
                <group name="WaveArmature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <group
                        name="CATRig_Pelvis_2"
                        position={[0, 0, -10.09]}
                        rotation={[0, 1.57, 0]}
                    >
                        <group
                            name="CATRigL_Leg1_2"
                            position={[-0.08, 0, 5.41]}
                            rotation={[3.14, 0, 3.14]}
                        />
                        <group name="CATRigSpine_2">
                            <group name="CATRigHead_2" position={[10.68, 0, 0]} />
                        </group>
                        <group
                            name="CATRigR_Leg1_2"
                            position={[-0.08, 0, -5.41]}
                            rotation={[-3.14, 0, -3.14]}
                        />
                        <group
                            name="CATRigR_Arm_2"
                            position={[4.64, 0, -10.98]}
                            rotation={[Math.PI, 0.96, -Math.PI]}
                        />
                        <group
                            name="CATRigL_Arm_2"
                            position={[4.64, 0, 10.98]}
                            rotation={[-Math.PI, -0.96, -Math.PI]}
                        />
                    </group>
                </group>
                <group name="WalkArmature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <group
                        name="CATRig_Pelvis_3"
                        position={[-0.39, 0, -9.15]}
                        rotation={[Math.PI, 1.54, -Math.PI]}
                    >
                        <group
                            name="CATRigL_Leg1_3"
                            position={[-0.08, 0, 5.41]}
                            rotation={[3.13, 0, 2.67]}
                        />
                        <group name="CATRigSpine_3">
                            <group name="CATRigHead_3" position={[10.68, 0, 0]} />
                        </group>
                        <group
                            name="CATRigR_Leg1_3"
                            position={[-0.08, 0, -5.41]}
                            rotation={[-3.13, -0.01, -2.49]}
                        />
                        <group
                            name="CATRigR_Arm_3"
                            position={[4.64, 0, -10.98]}
                            rotation={[2.55, 0.95, 3.14]}
                        />
                        <group
                            name="CATRigL_Arm_3"
                            position={[4.64, 0, 10.98]}
                            rotation={[2.55, -0.95, -3.14]}
                        />
                    </group>
                </group>
                <group
                    name="FallingArmature"
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.01}
                >
                    <group
                        name="CATRig_Pelvis_4"
                        position={[0, -0.05, -10.09]}
                        rotation={[0.28, Math.PI / 2, 0]}
                    >
                        <group
                            name="CATRigL_Leg1_4"
                            position={[-0.08, 0, 5.41]}
                            rotation={[3.14, 0, -2.86]}
                        />
                        <group name="CATRigSpine_4" scale={[1.03, 0.98, 0.98]}>
                            <group
                                name="CATRigHead_4"
                                position={[10.68, 0, 0]}
                                scale={[1.03, 0.97, 0.97]}
                            />
                        </group>
                        <group
                            name="CATRigR_Leg1_4"
                            position={[-0.08, 0, -5.41]}
                            rotation={[-3.14, 0, -2.86]}
                        />
                        <group
                            name="CATRigR_Arm_4"
                            position={[4.64, 0, -10.98]}
                            rotation={[-1.16, 1.34, 1.05]}
                        />
                        <group
                            name="CATRigL_Arm_4"
                            position={[4.64, 0, 10.98]}
                            rotation={[0.61, -1.16, 0.31]}
                        />
                    </group>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/Snakey.glb");