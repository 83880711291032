import { useState } from 'react';
import { Box, Typography, TextField, FormGroup, Button, Snackbar, Alert, CircularProgress } from "@mui/material"
import { StyledHeader } from './Features';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const Contact = () => {
    const [input, setInput] = useState({ name: null, mail: null, message: null })
    const [errorPopupOpen, setErrorPopupOpen] = useState(false);
    const [errorPopupMessage, setErrorPopupMessage] = useState("Enter a valid email")
    const [successPopupOpen, setSuccessPopupOpen] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);

    const handleSubmit = () => {
        if (input.name === null || input.mail === null || input.message === null ||
            input.name === '' || input.mail === '' || input.message === '') {
            setErrorPopupMessage("Fill all input fields");
            setErrorPopupOpen(true);
            return;
        }

        if (!validateEmail(input.mail)) {
            setErrorPopupMessage("Enter a valid email");
            setErrorPopupOpen(true);
            return;
        }

        setSendingMessage(true);

        var myHeaders = new Headers();
        var formData = new FormData();
        formData.append("entry.1444651604", input.name);
        formData.append("entry.324561919", input.mail);
        formData.append("entry.1558748762", input.message);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: formData
        };

        // Submit to server
        fetch("https://docs.google.com/forms/u/1/d/e/1FAIpQLSdT9TH0jvZn5M5WhRjW76igVw0uOAv0AGBcrykuzT0zXzhNAA/formResponse", requestOptions)
            .then(response => {
                response.text()
            })
            .then(result => {
                setInput({
                    name: "",
                    mail: "",
                    message: ""
                });
                setSuccessPopupOpen(true);
                setSendingMessage(false);
            })
            // TODO As long as the user has internet connection, this error can be ignored for now
            .catch(error => {
                setInput({
                    name: "",
                    mail: "",
                    message: ""
                });
                setSuccessPopupOpen(true);
                setSendingMessage(false);
            });
    }

    // Field ID should match property name of input object
    const handleInput = (fieldId) => (e) => {
        setInput(prev => {
            const updated = { ...prev };
            updated[fieldId] = e.target.value;
            return updated;
        })
    }

    const textFieldStyle = {
        width: "90vw",
        maxWidth: "800px",
        marginBottom: "20px"
    }

    return (
        <div className="page-content">
            <Box id='contact' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mb: 15, p: '5%' }}>
                {/* <StyledHeader header='contact us' /> */}
                <Typography align='center' fontSize={{xs: 50, sm: 90, md: 110}} lineHeight='0.9' variant='h1' fontFamily='Poppins'>Want to<br />talk?</Typography>
                <Typography sx={{display: {xs: 'none', sm: 'block'}}} align='center' variant='body2' fontSize={{xs: 18, sm: 22, md: 24, lg: 28}}>Our lines are always open if you would like to<br />talk or just share any feedback or thoughts.</Typography>
                <Typography sx={{display: {xs: 'block', sm: 'none'}}} align='center' variant='body2' fontSize={{xs: 18}}>Our lines are always open if you would like to talk or just share any feedback or thoughts.</Typography>

                <FormGroup
                    component='form'
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <TextField
                        style={{
                            ...textFieldStyle,
                            marginTop: "1%"
                        }}
                        required
                        label='Full Name'
                        variant='filled'
                        onChange={handleInput('name')}
                        value={input.name}
                    />
                    <TextField
                        style={textFieldStyle}
                        required
                        label='Email Address'
                        variant='filled'
                        onChange={handleInput('mail')}
                        value={input.mail}
                    />
                    <TextField
                        style={{
                            ...textFieldStyle,
                            marginBottom: "0%"
                        }}
                        required
                        multiline={true}
                        minRows={5}
                        label='Message'
                        variant='filled'
                        onChange={handleInput('message')}
                        value={input.message}
                    />
                </FormGroup>

                {
                    !sendingMessage ?
                        <Button
                            variant='contained'
                            style={{ width: '150px', marginTop: '25px' }}
                            onClick={() => {
                                handleSubmit();
                            }}>
                            <Typography variant='subtitle1' color='primary.contrastText'>
                                Submit
                            </Typography>
                        </Button>
                        :
                        <CircularProgress
                            size={30}
                            disableShrink={true}
                            style={{
                                color: "#268513",
                            }}
                        ></CircularProgress>
                }

                <Snackbar
                    open={errorPopupOpen}
                    autoHideDuration={2000}
                    onClose={() => {
                        setErrorPopupOpen(false);
                    }}>
                    <Alert
                        variant="filled"
                        severity="error"
                        sx={{
                            display: "flex",
                            flexDirection: "center",
                            alignItems: "center",
                            boxShadow: 1
                        }}>
                        {errorPopupMessage}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={successPopupOpen}
                    autoHideDuration={5000}
                    onClose={() => {
                        setSuccessPopupOpen(false);
                    }}>
                    <Alert
                        variant="filled"
                        severity="success"
                        sx={{
                            display: "flex",
                            flexDirection: "center",
                            alignItems: "center",
                            boxShadow: 1
                        }}>
                        {"Thank you! We will be in touch with you shortly :)"}
                    </Alert>
                </Snackbar>
            </Box >
        </div>
    )
}

export default Contact;