import { CustomButton } from './Trial'
import { Box, ButtonBase, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom';

const ConnectBar = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                bgcolor: 'primary.main',
                zIndex: 1               // make sure it's on top of everything else
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    width: "100%",
                    height: { xs: '60px', sm: '80px' },
                    alignContent: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    zIndex: 1
                }}>
                <CustomButton width='43px' href={"https://www.linkedin.com/company/floramis/"} buttonImage={require("../assets/imgs/linkedin.png")} />
                <CustomButton width='35px' href={"https://www.instagram.com/floramisapp"} buttonImage={require("../assets/imgs/instagram.png")} />
                <CustomButton width='35px' href={"https://www.facebook.com/profile.php?id=100083270180353"} buttonImage={require("../assets/imgs/facebook.png")} />
                <CustomButton width='35px' href={"https://www.tiktok.com/@floramisapp"} buttonImage={require("../assets/imgs/tiktok.png")} />
                <CustomButton width='40px' href={"https://discord.gg/VmR734HB84"} buttonImage={require("../assets/imgs/discord.png")} />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    pb: 2
                }}
            >
                <ButtonBase
                    sx={{
                        px: 2
                    }}
                    href={"/privacy-policy"}
                >
                    <Typography color='primary.contrastText'>Privacy Policy</Typography>
                </ButtonBase>

                <ButtonBase
                    sx={{
                        px: 2
                    }}
                    href={"/pdpa"}
                >
                    <Typography color='primary.contrastText'>PDPA</Typography>
                </ButtonBase>
            </Box>
        </Box>
    )
}

export default ConnectBar;