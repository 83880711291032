import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Typography, Box } from "@mui/material";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


// TODO: Dynamic sizing of elements with screen size, and dynamic margin adjustment.
// TS causemalfunction in mui Box element
// export interface InfiniteReelProps {
//     reelTexts: string[];
//     duration: number[];
//     directionArr: number[];
//     initialXOffsetFunction?: (index: number) => number;
//     strokeFunction?: (itemIndex: number, rowIndex: number) => number;
//     affectOnScrollArr: boolean[];
//     containerRotation?: number;
//     velocityOnScroll?: number;
//     smoothScrolling?: any;
// }

const InfiniteReel = ({
    reelTexts = [],
    duration = [],
    directionArr = [],
    initialXOffsetFunction = (index) => {
        console.log('Init x: ' + (-index % 2) * 250);
        return (-index % 2) * 250;
    },
    strokeFunction = (itemIndex) => !(itemIndex % 2),
    affectOnScrollArr = [],
    containerRotation = 0,
    velocityOnScroll = 100,
    smoothScrolling,
}) => {
    // STores last offset point animator before the component was tore down
    const [nextAnimStartOffset, setNextAnimStartOffset] = useState(0);
    let wrappedX = 0;

    const direction = useRef(1);
    const watchDirection = useRef([]);
    const ItemRefs = useRef([]);
    const AllItemsRef = useRef(null);
    const ItemRowRef = useRef([]);

    const wrap = (value, min, max) => {
        var v = value - min;
        var r = max - min;
        return ((v % r) + min);
    };

    const getItemWidth = () => {
        return ItemRefs.current[0].clientWidth;
    };

    const directionChanged = (index) => {
        if (direction.current == watchDirection.current[index]) {
            return false;
        } else if (direction.current != watchDirection.current[index]) {
            watchDirection.current[index] = direction.current;
            return true;
        }
        return true;
    };

    const setItemRef = (el) => {
        if (el && !ItemRefs.current.includes(el)) {
            ItemRefs.current.push(el);
        }
    };

    const setRowRef = (el) => {
        if (el && !ItemRowRef.current.includes(el)) {
            ItemRowRef.current.push(el);
        }
    };

    useEffect(() => {
        if (smoothScrolling) smoothScrolling();
        gsap.set(AllItemsRef.current, {
            rotation: containerRotation,
            xPercent: -5,
        });

        // Currently active animators, used for cleanup function
        const animators = [];

        ItemRowRef.current.forEach((el, index) => {
            const affectOnScroll = affectOnScrollArr[index];

            watchDirection.current.push(1);
            let tl;
            // When resize the repeat is not aligned
            gsap.set(el, { x: nextAnimStartOffset });
            tl = gsap.to(el, {
                repeat: -1,
                x: () => {
                    console.log('recalculate item width')
                    return `+=${getItemWidth() * 2}`;
                },
                duration: duration[index],
                paused: affectOnScroll,
                ease: "none",
                // The wrap needs to be updated together with the weights
                modifiers: {
                    x: gsap.utils.unitize((x) => {
                        wrappedX = wrap(x, -getItemWidth() * 2, 0)
                        // console.log(wrappedX);
                        return wrappedX;
                    }),
                },
            });

            animators.push(tl);

            if (affectOnScroll)
                ScrollTrigger.create({
                    trigger: ".react-infinite-text-reel-items",
                    start: "top bottom",
                    end: "bottom top",
                    onEnter: () => {
                        tl.play();
                    },
                    onEnterBack: () => tl.play(),
                    onLeave: () => {
                        gsap.killTweensOf(el);
                    },
                    onLeaveBack: () => {
                        gsap.killTweensOf(el);
                    },
                    onUpdate: (self) => {
                        let vel = self.getVelocity() / velocityOnScroll;
                        console.log(self.direction, direction, index);
                        if (directionChanged(index)) {
                            tl = gsap.to(el, {
                                repeat: -1,
                                x: () =>
                                    self.direction * directionArr[index] == 1
                                        ? `+=${getItemWidth() * 2}`
                                        : `-=${getItemWidth() * 2}`,
                                duration: duration[index],
                                ease: "none",
                                modifiers: {
                                    x: gsap.utils.unitize((x) => {
                                        return wrap(x, getItemWidth() * -2, 0);
                                    }),
                                },
                            });
                        }
                        if (vel > 1 || vel < -1) {
                            tl.timeScale(Math.abs(vel));
                        }

                        direction.current = self.direction;
                    },
                });
        });
        return () => {
            for (let i = 0; i < animators.length; i++) {
                animators[i].kill();
                animators[i] = null;
            }
        }
    });

    // Listen for screen size change
    useEffect(() => {
        function handleResize() {
            setNextAnimStartOffset(wrappedX);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    })

    const renderItems = (text, rowIndex) => {
        return new Array(10).fill(1).map((_, itemIndex) => {
            const stroke = strokeFunction(itemIndex, rowIndex);

            return (
                <div
                    key={itemIndex}
                    ref={setItemRef}
                    className={`react-infinite-text-reel-item ${stroke ? "react-infinite-text-reel-item-stroke" : ""
                        } react-infinite-text-reel-row-${rowIndex + 1}-item-${itemIndex + 1}`}
                >
                    <Typography sx={{ display: 'inline' }} variant='h1' color='secondary.main' fontFamily='Poppins'>{text}</Typography>
                </div>
            );
        });
    };

    // Make font size adjustment manual breakpoints
    return (
        <Box sx={{
            height: '8rem',
            mb: { xs: '75px', sm: '100px' },
            mt: { xs: '25px', sm: '100px' },
            overflow: "hidden",
        }}>
            <div style={{
                left: 0,
                right: 0,
                position: 'absolute',
                overflow: 'hidden'
            }}>
                <div ref={AllItemsRef} className="react-infinite-text-reel-items">
                    {reelTexts.map((text, rowIndex) => (
                        <div
                            key={rowIndex}
                            ref={setRowRef}
                            className={`react-infinite-text-reel-row react-infinite-text-reel-row-${rowIndex + 1}`}
                        >
                            {renderItems(text, rowIndex)}
                        </div>
                    ))}
                </div>
            </div>
        </Box >
    );
};

export default InfiniteReel;