import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material"
import Typed from 'typed.js';

import "../App.css";

const InfoPageTitle = () => {
    const [anim, setAnim] = useState({ state: false, suffix: '' });
    // Create reference to store the DOM element containing the animation
    const el = useRef(null);
    // Create reference to store the Typed instance itself
    const typed = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            const options = {
                strings: [
                    'PLANT',
                    'FLORAMI',
                ],
                typeSpeed: 100,
                backSpeed: 100,
                cursorChar: 'I',
                onComplete: () => setAnim({ state: true, suffix: 'anim-completed' })
            }

            // elRef refers to the <span> rendered below
            typed.current = new Typed(el.current, options);
            typed.current.start();
        }, 500);

        return () => {
            // Make sure to destroy Typed instance during cleanup
            // to prevent memory leaks
            typed.current.destroy();
        }
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '2.5%',
            paddingTop: 0
        }}>
            <Typography
                className={anim.suffix}
                variant='h1'
                fontSize={{ xs: 48, sm: 90, md: 110 }}
                lineHeight='0.9'
                align='left'
                sx={{
                    minHeight: '100vh',
                    paddingTop: {
                        xs: "80px",
                        sm: "90px",
                        md: "100px"
                    },
                    // Only need a paddingLeft for mobile phones
                    paddingLeft: {
                        xs: "10px",
                        sm: "0px",
                        md: "0px"
                    },
                }}>
                FORGING<br />THE<br />SOLARPUNK<br />SOCIETY OF<br />TOMORROW.<br /><br />ONE<br />{anim.status ? 'FLORAMI' : <span ref={el}></span>}<br />AT A TIME
            </Typography>
        </Box>
    )
}

export default InfoPageTitle;