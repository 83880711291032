import { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  Button,
  ButtonBase
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;
const navHomeItem = { name: 'Home', link: '', anchor: null };
const tryItItem = { name: 'Try', link: '', anchor: '#trial' };
const navItems = [
  { name: 'Features', link: '', anchor: "#features" },
  { name: 'Our Team', link: 'info', anchor: null },
  { name: 'Contact Us', link: 'info', anchor: "#contact" }
];

const NavBar = () => {

  const [target, setTarget] = useState({ link: null, anchor: null })
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSectionSkip = (name, link, anchor) => (event) => {
    window.fbq("trackCustom", "Clicked_" + name);
    event.preventDefault();
    setTarget({ link: link, anchor: anchor })
  }

  // Handle section skipping
  useEffect(() => {
    const parsedPath = location.pathname.split("/");
    if (target.link !== null && target.anchor !== null && parsedPath[parsedPath.length - 1] === target.link) {
      console.log("Skipping to " + target.anchor);
      const anchor = document.querySelector(target.anchor);
      anchor.focus();
      // Kind of a hack the inaccurate scroll when switching from one page to another is probably because the dom information is incomplete
      setTimeout(() => window.scrollTo(0, anchor.offsetTop - 70), 50);
      setTarget({ link: null, anchor: null })
    } else if (target.link !== null && target.anchor === null && parsedPath[parsedPath.length - 1] === target.link) {
      window.scrollTo(0, 0);
      setTarget({ link: null, anchor: null })
    }
  }, [target, location.pathname])

  const handleDrawerToggle = (open) => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Divider />
      <List>
        {navItems.map((item) => {
          return (
            <ListItem sx={{ pl: 0, pr: 0 }} key={item.name}>
              <Button sx={{ width: '100%' }} key={item.name} onClick={handleSectionSkip(item.name, item.link, item.anchor)}>
                <NavLink to={item.link} style={{ textDecoration: 'none' }}>
                  <Typography color='primary.main' >{item.name}</Typography>
                </NavLink>
              </Button>
            </ListItem>
          )
        })}
        <ListItem sx={{ pl: 0, pr: 0 }} key={'trial'}>
          <Button variant='contained' sx={{ m: 'auto', mt: 2 }} onClick={handleSectionSkip(tryItItem.name, tryItItem.link, tryItItem.anchor)}>
            <NavLink to={tryItItem.link} style={{ textDecoration: 'none' }}>
              <Typography color='primary.contrastText' variant='navButtonText' fontSize={14} >Try It Now</Typography>
            </NavLink>
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" sx={{ bgcolor: 'background.main', boxShadow: 5 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ width: '250px', alignItems: 'center', display: 'flex', justifyContent: 'space-evenly' }}>
            <img style={{ width: '20%', height: '20%' }} src={require('../assets/imgs/Logo.png')} alt='logo' />
            <ButtonBase onClick={handleSectionSkip(navHomeItem.name, navHomeItem.link, navHomeItem.anchor)}>
              <NavLink to={navHomeItem.link} style={{ textDecoration: 'none' }}>
                <img style={{ width: '95%', height: '95%', marginTop: '2%' }} src={require('../assets/imgs/floramis_text_logo_cropped.png')} alt='floramis' />
              </NavLink>
            </ButtonBase>
          </Box>

          {/* Box containing page links */}
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item.name} onClick={handleSectionSkip(item.name, item.link, item.anchor)}>
                <NavLink to={item.link} style={{ textDecoration: 'none' }}>
                  <Typography color='primary.main' variant='navButtonText' >{item.name}</Typography>
                </NavLink>
              </Button>
            ))}
            <Button variant='contained' sx={{ ml: 1 }} onClick={handleSectionSkip(tryItItem.name, tryItItem.link, tryItItem.anchor)}>
              <NavLink to={tryItItem.link} style={{ textDecoration: 'none' }}>
                <Typography color='primary.contrastText' variant='navButtonText' fontSize={14} >Try It Now</Typography>
              </NavLink>
            </Button>
          </Box>

          {/* Drawer stack icon  */}
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mt: 0.5, mr: 0, display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          anchor={'right'}
          onClose={handleDrawerToggle}
          onOpen={() => { }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Box>
      <div>
        <Outlet />
      </div>
    </Box>
  );
};

export default NavBar;