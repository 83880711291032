import { Typography, Box, ButtonBase, Button, Dialog, Snackbar, Alert, CircularProgress, TextField, FormGroup } from '@mui/material'
import { useState } from 'react'

import "../Animations.css";
import { getMobileOperatingSystem } from '../common/Utils';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

// TODO: Provide actual links for buttons
const Trial = () => {
    const appStoreButtonStyle = {
        backgroundColor: "primary.main",
        padding: "5%",
        boxShadow: 5,
        borderRadius: "10px",
        ':hover': {
            transform: "scale(1.05)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        },
        ':not(:hover)': {
            transform: "scale(1)",
            transition: "transform 0.2s ease-out",
            willChange: "transform"
        }
    }

    return (
        <div className="page-content">
            <Box id="trial" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, pb: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', maxWidth: '1000px' }}>
                    <Box sx={{ display: 'flex', textAlign: { xs: 'center', md: 'left' }, flexDirection: 'column', width: { xs: '90%', md: '40%' }, p: 2, pl: { xs: 2, md: 6 } }}>
                        <Typography lineHeight='0.9' variant='h1' fontFamily='Poppins'>GIVE IT<br />A TRY</Typography>
                        <Typography sx={{ display: { xs: 'none', md: 'block' } }} variant='subtitle1'>Download our demo today and<br />click the button to stay updated on the latest Floramis news</Typography>
                        <Typography sx={{ display: { xs: 'block', md: 'none' }, pl: '10vw', pr: '10vw', pt: 2 }} variant='subtitle1'>Download our demo today and click the button to stay updated on the latest Floramis related news</Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'inline-block' }, width: '30%', p: 5, pl: 2 }}>
                        <img style={{ width: '100%' }} src={require('../assets/imgs/happy_plant.png')} alt='Happy plant' />
                    </Box>
                </Box>

                <DownloadButton 
                    sx={appStoreButtonStyle}
                    href="https://floramis.com/download"
                ></DownloadButton>
            </Box>
        </div>
    )
}

const CustomButton = (props) => {
    return (
        <ButtonBase
            sx={props.sx}
            href={props.href}
            disableRipple={true}
            target='_blank'
            onClick={props.onClick}
        >
            <img style={{ width: props.width }} src={props.buttonImage} alt='Button icon' />
        </ButtonBase>
    )
}

const DownloadButton = (props) => {
    return (
        <ButtonBase
            sx={props.sx}
            href={props.href}
            disableRipple={true}
            // target='_blank'
            onClick={props.onClick}
        >
            <Typography color="white" lineHeight='0.9' variant='h2' fontFamily='Poppins'>DOWNLOAD NOW</Typography>
        </ButtonBase>
    )
}

export { CustomButton };
export default Trial;