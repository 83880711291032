import StartPage from '../components/StartPage';
import Features from '../components/Features'
import Cards from '../components/Cards'
import Trial from '../components/Trial'

function Home() {
    return (
        <div className='page'>
            <StartPage />
            <Features />
            <Cards />
            <Trial />
        </div>
    );
}

export default Home;