import Motive from '../components/Motive'
import Join from '../components/Join'
import Contact from '../components/Contact'
import InfoPageTitle from '../components/InfoPageTitle'

const Info = () => {
    return (
        <div className='page'>
            <InfoPageTitle />
            <Motive />
            <Join />
            <Contact />
        </div>
    )
}

export default Info;