import { Box } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

const LocalHTMLPage = (props) => {
  return (
    <div className="page">
      <Box
        className="page-content"
        sx={{
          height: "100%",
          // overflow: "hidden",
          paddingTop: {
            xs: "80px",
            // sm: "90px",
            md: "100px",
          },
        }}
      >
        <FrameWrapper html={process.env.PUBLIC_URL + props.link} />
      </Box>
    </div>
  );
};

const FrameWrapper = ({ html }) => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");

  const onLoad = () => {
    console.log(ref.current.contentWindow.document.body.scrollHeight);
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <iframe
      ref={ref}
      onLoad={onLoad}
      src={html}
      height={height}
      width="100%"
      style={{
        border: "none",
        width: "100%",
        height: height,
      }}
    ></iframe>
  );
};

export default LocalHTMLPage;
