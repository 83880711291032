import { Box, Typography } from "@mui/material";
import InfiniteReel from "./InfiniteTextReel";

const Motive = () => {
    return (
        <div>
            <InfiniteReel
                reelTexts={['about us']}
                duration={[8]}
                directionArr={[1]}
                affectOnScrollArr={true}
            />
            <div className='page-content'>
                <Box sx={{ display: 'flex', flexDirection: 'column', p: '5%', pt: 0, gap: 6 }}>
                    <Typography variant='body2' fontSize={{ xs: 18, sm: 22, md: 24, lg: 28 }} align='center' sx={{ p: '5vw' }}>
                        We hear talk of <span className='highlight-text'>sustainability</span> very often these days.
                        However, most attempts to imbibe sustainable practices are <span className='highlight-text'>indirect measures </span>
                        such as ‘stop using plastic straws’. These are impactful but will take a long to time to actualize.<br /><br />
                        We believe in starting sustainable practices with your <span className='highlight-text'>direct environment</span>.
                        Conserve and enjoy the plants around you. If we can get people <span className='highlight-text'>interested in plants </span>
                        beyond seeing them as simple aesthetic décor today, they might be planting their own home gardens tomorrow.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={require("../assets/imgs/founders.png")} alt='founders' style={{ width: '90%', maxWidth: '1200px' }} />
                    </Box>
                    <Typography variant='body2' fontSize={{ xs: 18, sm: 22, md: 24, lg: 28 }} align='center' sx={{ p: '5vw' }}>
                        Raghav and Ojus noticed a trend in their peers purchasing indoor plants but being unable to keep them alive beyond a few months.
                        They thought about how they could prevent this plant massacre and realized how
                        <span className='highlight-text'> combining plantcare with the fun of virtual pets </span>
                        could be a potential opportunity. <br /><br />Thus, Floramis was born to target the younger
                        generation by incetivizing them to learn about the plants around them in a <span className='highlight-text'>gamified manner </span>
                        and create the sustainable thinkers of the future.
                    </Typography>
                </Box>
            </div>
        </div>
    )
}

export default Motive;