import { Box, Button, Typography } from "@mui/material";

// TODO: mobile version
const Join = () => {
    return (
        <div className='page-content'>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 10, mt: 5, pl: '20%', pr: '20%', }}>
                <Box sx={{ textAlign: { xs: 'center', md: 'left' }, display: 'flex', flexDirection: 'column', gap: 2, mt: 5 }}>
                    <Typography lineHeight='0.9' variant='h1' fontSize={{ xs: 50, sm: 90, md: 110 }} fontFamily='Poppins'>
                        Join the<br />floramis<br />team<br />today
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'stretch' } }}>
                        <Typography sx={{ width: { xs: '90%', md: '65%' }, fontSize: { xs: 18, sm: 20, md: 24, lg: 28 } }} variant='body2'>
                            We are always looking for talented individuals to join the team. If you share our vision for a greener future join us using the link below
                        </Typography>
                        <Box sx={{ display: { xs: 'none', md: 'inline-block' }, width: '35%', position: 'relative', bottom: '15vw' }}>
                            <img style={{ width: '100%' }} src={require('../assets/imgs/feature_3.png')} alt='Happy plant' />
                        </Box>
                    </Box>
                </Box>

                <Button
                    variant='contained'
                    sx={{
                        width: '150px',
                        position: 'relative',
                        bottom: { xs: 0, md: '4vw' },
                        mt: { xs: 5, sm: 5, md: -5, lg: -12 }
                    }}
                    onClick={() => {
                        window.location.href = "https://www.linkedin.com/jobs/search/?currentJobId=3363917428&f_C=89524755&geoId=92000000&originToLandingJobPostings=3363917428";
                    }}>
                    <Typography
                        variant='subtitle1'
                        color='primary.contrastText'>
                        Join us
                    </Typography>
                </Button>
            </Box>
        </div>
    )
}

export default Join;